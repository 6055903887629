@import '../../common/commonConstant.scss';

.AddNewUserByType {
  .template-white-box {
    margin-bottom: 20px;
    .title-choice {
      font-size: 14px;
      min-width: 180px;
    }
    .box-select-type {
      display: flex;
      align-items: center;
      .select-type {
        width: 300px;
        color: $text-color-black2 !important;
        margin-right: 20px;
      }
    }
    .box-select-org {
      display: flex;
      align-items: center;
      .select-org {
        width: calc(100% - 180px);
        .org {
          width: calc(60% - 10px);
        }
      }
    }
    .box-title-privilege {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .box-delete-privilege {
        color: $text-color-red;
        font-size: 14px;
        font-weight: 300;
        cursor: pointer;
        .icon {
          font-size: 12px;
          margin-right: 10px;
        }
      }
    }
    .box-select-area {
      display: flex;
      align-items: center;
      .box-item-select {
        display: flex;
        align-items: center;
        width: calc(100% - 180px + 20px);
        margin: 0px -10px;
        > .item-select {
          width: 20%;
          padding: 0px 10px;
        }
      }
    }
    .box-select-role {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .select-role {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 180px);
        .role {
          width: calc(60% - 8px);
        }
        .ButtonWithIcon {
          width: calc(20% - 10px);
          padding-left: 10px;
        }
      }
    }
    .box-user {
      .unit-title {
        font-weight: 500;
        font-size: 16px;
        color: $text-color-blue;
      }
      .box-detail {
        display: flex;
        .left {
          display: flex;
          width: calc(60% + 64px);
          flex-direction: column;
          .item {
            display: flex;
            align-items: center;
            margin-top: 20px;
            &:first-child {
              margin-top: 10px;
            }
            .input {
              width: 100%;
            }
          }
        }
        .right {
          margin-left: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40%;
          .box-bg {
            background: $add-new-user-by-type-upload-bg;
            border: $add-new-user-by-type-upload-border;
            border-radius: 50%;
            width: 150px;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: $text-color-gray3;
            cursor: pointer;
            .icon {
              font-size: 50px;
            }
            .text {
              font-weight: 500;
              font-size: 14px;
              margin-top: 5px;
            }
          }
          .dummy {
            display: none;
          }
        }
      }
    }
    .ButtonWithIcon {
      .btn {
        min-width: unset;
        padding: 8px 10px !important;
        .text {
          font-size: 14px;
          margin-right: 10px;
        }
      }
    }
  }
  .box-add-privilage {
    text-align: center;
    margin-bottom: 20px;
  }
  .box-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    .btn:first-child {
      margin-right: 30px;
    }
  }
}
