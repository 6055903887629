@import './common/commonConstant.scss';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-day-picker/lib/style.css';

html {
  body {
    margin: 0px;
    font-family: $font-family-kanit;
    color: $text-color-black;
    font-weight: 300;
    background-color: $background-color-gray;
    #root {
      .App {
        // input
        input {
          &::placeholder {
            color: $input-placeholder;
            font-weight: 300;
          }
          &:focus {
            border: $input-placeholder-focus;
          }
          &:disabled {
            border: $input-placeholder-disable;
            background-color: $input-placeholder-disable-bg;
            color: $input-placeholder-disable-text;
          }
        }
        // tag a or link
        .link {
          color: $link-text;
          text-decoration: underline;
          &:hover {
            color: $link-text-hover;
          }
          &:active {
            color: $link-text-active;
          }
        }
        // button
        .btn {
          font-weight: 400;
          border-radius: 5px;
          box-shadow: $btn-shadow;
          // padding: 15px 20px;
          font-size: 18px;
          &.btn-secondary {
            background: $btn-secondary-bg;
            color: $btn-secondary-text;
            border: unset;
            &:hover {
              background-color: $btn-secondary-bg-hover;
            }
            &:active {
              background-color: $btn-secondary-bg-active;
            }
          }
          &.btn-primary {
            background: $btn-primary-bg;
            color: $btn-primary-text;
            border: unset;
            &:hover {
              background: $btn-primary-bg-hover;
            }
            &:active {
              background: $btn-primary-bg-active;
            }
          }
          &.btn-thirdary {
            background: $btn-thirdary-bg;
            color: $btn-thirdary-text;
            border: unset;
            &:hover {
              background: $btn-thirdary-bg-hover;
            }
            &:active {
              background: $btn-thirdary-bg-active;
            }
          }
        }
        .disabled {
          cursor: not-allowed;
        }
        // hr
        hr.hr {
          border: unset;
          border-bottom: $hr-border;
          margin: 10px 0px 20px;
        }
        // template
        .template-center {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 20px;
          min-height: 100vh;
          .box-page {
            width: 100%;
            .logo {
              max-width: 180px;
              margin: 0px auto 20px;
              display: block;
            }
            .box-form {
              padding: 30px 35px;
              background-color: $template-center-form-login-bg;
              border-radius: 5px;
              box-shadow: $template-center-form-login-bg-shadow;
              max-width: 420px;
              margin: 0px auto;
              .title {
                font-size: 30px;
                color: $text-color-blue;
                text-align: center;
                font-weight: 500;
              }
              .description {
                font-size: 18px;
                color: $text-color-gray;
              }
              .InputWithIcon {
                margin-top: 15px;
                &:first-child {
                  margin-top: 0px;
                }
              }
              .link {
                display: block;
              }
              .btn {
                width: 100%;
              }
            }
          }
        }
        .template-error {
          position: relative;
          margin-top: 80px;
          margin-bottom: 30px;
          .box-page {
            text-align: center;
            .error-title {
              position: absolute;
              top: calc(50% - 350px);
              left: calc(50% - 259.5px);
              font-size: 300px;
              color: $template-error-title;
            }
            .box-detail {
              min-height: calc(100vh - 80px - 30px);
              align-items: center;
              display: flex;
              flex-direction: column;
              justify-content: center;
              position: relative;
              .error-icon {
                max-width: 120px;
                margin-bottom: 15px;
              }
              .error-detail {
                font-size: 36px;
                color: $text-color-blue;
              }
              .error-description {
                font-size: 20px;
                color: $text-color-gray;
                max-width: 375px;
                margin: 0px auto 65px;
              }
              .btn {
                width: 100%;
                max-width: 340px;
              }
            }
          }
        }
        .template-with-sidebar {
          display: flex;
          min-width: 1080px;
          > .box-page {
            width: calc(100% - 270px);
            margin-left: 270px;
            &.size-small {
              width: calc(100% - 98px);
              margin-left: 98px;
            }
          }
        }
        .template-with-navbar {
          margin-top: 90px;
          .template-error {
            position: relative;
            .box-page {
              text-align: center;
              .box-detail {
                min-height: calc(100vh - 70px - 60px - 15px - 80px - 30px);
              }
            }
          }
        }
        .template-with-sidebar-navbar {
          padding: 30px;
          &.tracking-navbar {
            >.template-white-box {
              padding: 20px;
            }
          }
        }
        .template-white-box {
          background-color: $template-white-box-bg;
          box-shadow: $template-white-box-shadow;
          border-radius: 5px;
          padding: 30px 20px 35px;
          .template-title {
            font-weight: 500;
            font-size: 20px;
            color: $text-color-black2;
            .example-csv-excel {
              margin-top: 12px;
              padding-top: 6px;
              border-top: $border-main;
              font-size: 12px;
              font-weight: 100;
              a {
                margin-left: 12px;
                color: $link-text;
              }
            }
            .sch-btn {
              display: flex;
              align-items: center;
              .box-btn {
                text-align: right;
              }
              .box-upload {
                display: flex;
                width: 100%;
                .btn-upload {
                  display: block;
                  font-size: 16px;
                  color: #fff;
                  background: $btn-thirdary-bg-active;
                  padding: 3px 20px;
                  margin: 0;
                  border-radius: 4px;
                  cursor: pointer;
                }
                .show-file-name {
                  font-weight: 100;
                  color: $link-text-hover;
                  text-decoration: underline;
                  .icon-close {
                    font-size: 10px;
                    color: #fff;
                    border-radius: 50%;
                    padding: 8px;
                    background: $text-color-red;
                    cursor: pointer;
                  }
                }
                input {
                  max-width: 500px;
                  cursor: pointer;
                  &:focus, &:active {
                    box-shadow: unset !important;
                  }
                  &.hide {
                    display: none;
                  }
                }
                span {
                  white-space: nowrap;
                  margin-right: 35px;
                }

              }
            }
          }
          .req {
            color: $text-color-red;
            margin-left: 5px;
          }
        }
      }
    }
  }
}

.pagination {
	padding-left: 0;
	margin: 20px 0;
	border-radius: 4px;
	>li {
    display: inline;
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-right: none;
    background: #fff;
    &:last-child {
      border-right: 1px solid rgba(0, 0, 0, 0.24);
    }
		&.active > a {
			color: $text-color-blue;
			background-color: $text-color-gray3;
			cursor: default;
		}
		&.disabled > a {
			color: $text-color-gray;
			cursor: not-allowed;
		}
		>a {
			position: relative;
			float: left;
			color: #6C7177;
			font-size: 14px;
			padding: 4px 12px;
			text-decoration: none;
			margin-left: -1px;
		}
	}
}

.template-white-box {
  input {
    height: unset !important;
  }
  .box-title {
    display: flex;
    align-items: center;
    width: 100%;
    .title {
      margin-right: 30px;
    }
    .InputWithIcon {
      width: 100%;
      max-width: 500px;
    }
  }
  .box-select {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    .Dropdowns {
      margin: 0px 5px;
      &:first-child{
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
  .box-date {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    .title {
      font-size: 14px;
      font-weight: 400;
      margin-right: 10px;
      color: $text-color-black2;
      white-space: nowrap;
    }
    .label {
      font-size: 14px;
      font-weight: 400;
      color: $text-color-black2;
    }
    > .DatePicker {
      margin-right: 10px;
      &:last-child {
        margin: unset;
      }
    }
  }
  .box-status {
    display: flex;
    // align-items: center;
    margin-bottom: 10px;
    .title {
      font-size: 14px;
      font-weight: 400;
      margin-right: 10px;
      color: $text-color-black2;
      width: 100px;
    }
    .box-checkbox {
      display: flex;
      flex-wrap: wrap;
      .Checkbox {
        margin-right: 30px;
        &:last-child {
          margin-right: unset;
        }
      }
    }
  }
  .box-btn {
    text-align: right;
    > button {
      padding: 4px 10px !important;
      font-size: 18px;
      font-weight: 400;
      margin-left: 15px;
      .icon {
        font-size: 14px;
        margin-left: 20px;
      }
    }
  }
}
.overall {
  margin-top: 30px;
  margin-bottom: 22px;
  .overall-head {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .title {
      flex: 1;
      font-size: 24px;
    }
    .export {
      background-color: $template-white-box-bg;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 4px 8px;
      margin-left: 22px;
      font-size: 12px;
      color: $text-color-black;
      cursor: pointer;
      &.success {
        background: linear-gradient(360deg, #4CAF50 0%, #63D168 100%);
        color: #fff;
      }
    }
  }
  .overall-detail {
    display: flex;
    margin: 0 -10px;
    > .o-item {
      background-color: $template-white-box-bg;
      box-shadow: $template-white-box-shadow;
      flex: 1;
      border-radius: 5px;
      padding: 6px 10px;
      margin: 0 10px;
      span {
        color: $text-color-gray;
      }
      p {
        font-size: 30px;
        color: $text-color-blue;
        margin-bottom: 0;
      }
    }
  }
}

.yas-grid {
  &.fix-scroll {
    overflow-x: auto;
  }
  table {
    margin-bottom: 0;
    &.fix-scroll {
      th, td {
        white-space: nowrap;
      }
    }
    th {
      color: $text-color-blue;
      font-weight: normal;
      font-size: 14px;
      border-top: none;
      &.sorting {
        cursor: pointer;
        > span {
          margin-left: 12px;
          font-size: 9px;
        }
        &._active {
          color: $text-color-blue-hover;
        }
      }
    }
    tbody tr {
      &.pointer {
        cursor: pointer;
        &:hover {
          background: linear-gradient(90deg, #F5FAFF 0%, #F7F7F7 100%);
        }
      }
      td {
        font-size: 12px;
        color: $text-color-black2;
      }
    }
  }
}

.yas-pagination {
  display: flex;
  align-items: center;
  .d-left {
    flex: 1;
  }
}
.no-drop {
  cursor: no-drop !important;
}
.ready-for-ship {
  color: #34D1BF !important;
}
.picked {
  color: #FD8900 !important;
}
.packed {
  color: #FF715B !important;
}
.in-transit {
  color: #002F6C !important;
}
.received-order {
  color: #800080 !important;
}
.delivered {
  color: #04E762 !important;
}
.undelivered {
  color: #ff0000 !important;
}
.pointer {
  cursor: pointer !important;
}

.modal-order-details {
  background: rgba(1, 44, 99, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  padding: 50px 0;
  overflow-y: auto;
  z-index: 1000;
  width: 100%;
  height: 100%;
  .yas-order-detail {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }
  .head-detail, .body-detail {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(142, 153, 175, 0.2);
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 30px;
    h1 {
      font-weight: normal;
      font-size: 30px;
      color: $text-color-blue;
    }
  }
  .head-detail {
    span {
      font-size: 14px;
      color: $text-color-gray;
    }
    p {
      color: #212529;
    }
  }
  .intransit-status {
    border-radius: 6px;
    background: #e8ebef;
    padding: 6px 15px;
    margin-top: 15px;
  }
  .yas-modal-footer {
    text-align: center;
  }
  p {
    margin-bottom: 0;
  }
}

.text-div-right {
  float: right;
}
