@import '../../common//commonConstant.scss';

.AddNewUser {
  .box-select-radio-button {
    display: flex;
    align-items: center;
    .box-unit {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      width: 20%;
      min-width: 120px;
      max-width: 165px;
    }
    .box-choice {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .RadioButton {
        margin-right: 30px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .box-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    .btn:first-child {
      margin-right: 30px;
    }
  }
}
