.AreaManagementAdd {
  .area-form-title {
    font-size: 20px;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 12px;
  }
  .row {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 12px;
    margin: auto 0;
  }
  .section-footer {
    button {
      font-size: 18px !important;
      padding: 2px 15px !important;
      height: 38px;
      margin-left: 15px;
      &.btn-secondary {
        color: #fff !important;
        background: linear-gradient(360deg, #012A5F 0%, #002F6C 100%) !important;
      }
      &.btn-link {
        color: #888;
      }
    }
  }
  .organize-info {
    display: flex;
    align-items: center;
    background: #eee;
    border: 1px solid #ccc;
    padding: 4px 8px;
    border-radius: 5px;
    .organize-info-logo {
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 1px solid #002F6C;
      background: #fff;
      overflow: hidden;
      margin-right: 12px;
    }
  }
}