@import '../../common/commonConstant.scss';

.Profile {
  .template-white-box {
    display: inline-block;
    .box-name {
      display: flex;
      align-items: center;
      img {
        width: 100%;
        max-width: 40px;
        max-height: 40px;
        object-fit: contain;
        border-radius: 50%;
        margin-right: 10px;
      }
      .box {
        .user {
          font-size: 16px;
          color: $text-color-black;
        }
        .name {
          font-size: 30px;
          font-weight: 400;
          color: $text-color-blue;
          margin-top: -5px;
          margin-bottom: -5px;
        }
        .status {
          font-weight: 300;
          font-size: 12px;
          color: $text-color-gray3;
          .status-color {
            background-color: $text-color-green;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            display: inline-block;
            margin-left: 5px;
          }
        }
      }
    }
    .box-detail {
      .title {
        font-size: 14px;
        margin-bottom: 15px;
      }
      .box {
        .icon {
          font-size: 18px;
          color: $text-color-gray3;
          margin-right: 15px;
        }
        .title {
          font-size: 14px;
          color: $text-color-black;
          margin-right: 10px;
        }
        .detail {
          font-size: 16px;
          color: $text-color-black;
          .bold {
            font-weight: 400;
          }
        }
      }
    }
    .box-type {
      .title {
        font-size: 14px;
        color: $text-color-black;
      }
      .detail {
        font-size: 16px;
        font-weight: 400;
        color: $text-color-black;
      }
    }
    .box-org {
      .title {
        font-size: 14px;
        color: $text-color-black;
      }
      .detail {
        font-size: 16px;
        font-weight: 400;
        color: $text-color-black;
        img {
          width: 100%;
          max-width: 25px;
          max-height: 25px;
          object-fit: contain;
          border-radius: 50%;
          margin-right: 15px;
          border: $dropdowns-popup-image-border;
        }
      }
    }
    .box-privilage {
      .title {
        font-size: 14px;
        color: $text-color-black;
      }
      .privilage {
        font-size: 14px;
        font-weight: 400;
        .title {
          color: $text-color-blue;
        }
        .detail {
          color: $text-color-black;
        }
      }
    }
  }
}
