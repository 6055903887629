@import '../../common/commonConstant.scss';

.Dropdowns {
  background: #fff;
  position: relative;
  width: 100%;
  .image {
    border: $dropdowns-popup-image-border;
    border-radius: 50%;
    margin-right: 10px;
    width: 25px;
    height: 25px;
    object-fit: contain;
    background-color: $dropdowns-popup-image-bg;
  }
  .dummy-image {
    border: $dropdowns-popup-image-border;
    border-radius: 50%;
    margin-right: 10px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
  .box-dropdowns {
    border: $border-main;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.show {
      border: $input-placeholder-focus;
      .icon {
        border-left: $input-placeholder-focus;
      }
    }
    .text {
      padding: 5px 10px;
      color: $text-color-black2;
      display: flex;
      align-items: center;
      flex: 1;
      > span {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .placeholder {
      color: $input-placeholder;
      padding: 5px 10px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .icon {
      padding: 15px;
      border-left: $border-main;
      font-size: 7px;
      color: $text-color-blue;
    }
  }
  .box-items {
    border: $dropdowns-popup-border;
    box-shadow: $dropdowns-popup-shadow;
    border-radius: 4px;
    margin-top: 1px;
    display: none;
    background: $dropdowns-popup-bg;
    position: absolute;
    width: inherit;
    z-index: 1;
    max-height: 150px;
    overflow: auto;
    &.show {
      display: block;
    }
    .dummy {
      height: 20px;
    }
    .item {
      display: flex;
      padding: 10px 20px;
      font-size: 14px;
      border-bottom: $border-main;
      &:hover, &.active {
        background: $dropdowns-popup-item-hover;
      }
      &:last-child {
        border-bottom: unset;
      }
    }
  }
}
