.Dashboard {
  padding: 30px;
  .c-filter {
    display: flex;
    align-items: center;
    font-size: 14px;
    text-align: right;
    margin-bottom: 12px;
    color: #002f6c;
    span {
      color: #aaa;
    }
  }
}
.txt-filter {
  margin-left: auto;
}
.filter-dropdown {
  margin-left: 15px;
  text-align: left;
  width: 220px;
}
.c-overall {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
  .c-item {
    padding: 13px;
    width: 100%;
    margin-right: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(142, 153, 175, 0.2);
    border-radius: 5px;
    &:last-child {
      margin-right: 0;
    }
    .c-type {
      color: #9c9c9c;
      font-size: 14px;
    }
    .c-total {
      color: #012a5f;
      font-size: 30px;
      margin-top: -8px;
      margin-bottom: -4px;
      small {
        font-size: 14px;
      }
    }
    .c-unit {
      color: #012a5f;
      font-size: 13px;
    }
  }
}
.c-chart {
  margin-top: 12px;
  position: relative;
  display: flex;
  .c-txt {
    position: absolute;
    display: flex;
    width: 100%;
    font-size: 10px;
    .l-txt {
      color: #34d1bf;
      flex: 1;
      padding-left: 6px;
    }
    .r-txt {
      color: #f2994a;
      text-align: right;
      flex: 1;
      padding-right: 6px;
    }
  }
  .c-success {
    background: #f0f0f0;
    height: 15px;
    border-radius: 5px 0 0 5px;
  }
  .c-winning {
    background: #f6f6f6;
    height: 15px;
    border-radius: 0 5px 5px 0;
  }
}
.c-detail {
  padding: 20px 22px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(142, 153, 175, 0.2);
  border-radius: 5px;
  margin-bottom: 22px;
  .c-detail-header {
    display: flex;
    margin-bottom: 8px;
    div {
      font-size: 12px;
      display: flex;
      align-items: center;
      margin-left: 15px;
      &:nth-child(1) {
        margin-left: auto;
      }
      i {
        display: block;
        background: #eee;
        width: 12px;
        height: 12px;
        border-radius: 2px;
        margin-right: 8px;
      }
    }
  }
}
table.db-table {
  width: 100%;
  thead > tr > td {
    padding: 8px 0px;
    text-align: center;
  }
  tbody > tr {
    td {
      padding: 6px 0px;
      border-right: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
      font-size: 16px;
      &._w18 {
        width: 17%;
      }
      &._w28 {
        width: 28%;
      }
      &._w3 {
        width: 3%;
        border-right-color: transparent;
        border-bottom-color: transparent;
      }
      &._w3-op {
        width: 6%;
        padding: 6px 15px;
        border-right-color: transparent;
        border-bottom-color: transparent;
      }
      &:last-child {
        border-right-color: transparent;
      }
    }
    &:last-child td {
      border-bottom-color: transparent;
    }
  }
  .txt_success {
    padding: 0px 6px;
    color: #34d1bf;
    font-size: 12px;
  }
  .txt_winning {
    padding: 0px 6px;
    color: #f2994a;
    font-size: 12px;
  }
  ._div_chart {
    display: flex;
    align-items: center;
    ._chart_percent {
      position: relative;
      flex: 1;
      height: 10px;
      background: #eaeef3;
      border-radius: 0 2px 2px 0;
    }
    ._chart_txt {
      width: 42px;
      padding: 0 8px;
      text-align: right;
      font-size: 11px;
    }
    &._success .item_percent {
      background: #34d1bf;
      height: 10px;
      border-radius: 0 2px 2px 0;
    }
    &._winning .item_percent {
      background: #f2994a;
      height: 10px;
      border-radius: 0 2px 2px 0;
    }
  }
}
.c-pie-card {
  padding: 20px 22px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(142, 153, 175, 0.2);
  border-radius: 5px;
  margin-bottom: 22px;
  .c-pie-card-header {
    display: flex;
    align-items: center;
    .div-cpie-l {
      flex: 1;
      > ._title {
        font-size: 24px;
      }
    }
    .div-cpie-r {
      display: flex;
      div {
        font-size: 12px;
        display: flex;
        align-items: center;
        margin-left: 15px;
        i {
          display: block;
          background: #eee;
          width: 12px;
          height: 12px;
          border-radius: 2px;
          margin-right: 8px;
        }
      }
    }
  }
}
.c-pie-card-body {
  display: flex;
  .c-pie-item {
    flex: 1;
    text-align: center;
    div:nth-child(1) {
      height: 140px;
      >div {
        height: 140px !important;
        margin: 0 auto;
      }
    }
    ._title {
      font-size: 14px;
    }
    ._detail {
      font-size: 18px;
      color: #aaa;
    }
  }
}
.c-detail-table {
  padding: 20px 22px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(142, 153, 175, 0.2);
  border-radius: 5px;
  margin-bottom: 52px;
  table.opview {
    width: 100%;
    thead > tr > td {
      padding: 8px 0px;
      text-align: center;
      color: #9c9c9c;
    }
    tbody > tr {
      td {
        padding: 6px 18px;
        border: 1px solid transparent;
        border-right: 1px solid #dadada;
        font-size: 16px;
        text-align: right;
        width: 14%;
        b {
          color: #002f6c;
        }
        &:nth-child(1) {
          text-align: center;
          width: 10%;
          color: #9c9c9c;
          font-size: 14px;
        }
        &:last-child {
          text-align: center;
          border-right-color: transparent;
        }
      }
    }
  }
}
.div-pending-status {
  display: flex;
  > div {
    flex: 1;
    padding: 20px 22px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(142, 153, 175, 0.2);
    border-radius: 5px;
    margin-bottom: 22px;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
    &.pending-status {
      flex: 4;
      .pending-status-rows {
        display: flex;
        > div {
          flex: 1;
          &:last-child {
            border-left: 1px solid #eee;
          }
          > p {
            text-align: center;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.div-monthly-line {
  margin: 12px 0 32px 0;
  text-align: center;
  color: #aaa;
  width: 100%;
  position: relative;
  &::before,
  &::after {
    position: absolute;
    top: 50%;
    content: "";
    width: 40%;
    border-top: 1px solid #ccc;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
}
.div-monthly {
  display: flex;
  > div {
    padding: 20px 22px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(142, 153, 175, 0.2);
    border-radius: 5px;
    margin-bottom: 22px;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
  .order-status {
    width: 300px;
    .card-title {
      small {
        display: block;
        color: #aaa;
      }
    }
  }
  .div-completion {
    flex: 1;
    small {
      display: block;
      color: #aaa;
    }
    .completion-rows {
      display: flex;
      > div {
        flex: 1;
        p {
          text-align: center;
        }
      }
    }
  }
}

.top-product,
.top-op-client {
  > div {
    font-size: 14px;
    color: #9c9c9c;
    margin-bottom: 18px;
  }
  > p {
    font-size: 14px;
    margin-bottom: 8px;
  }
}

.div-and-table {
  display: flex;
  align-items: center;
  ._l {
    width: 150px;
    margin-right: 15px;
  }
  ._r {
    flex: 1;
  }
}

.bottom-headle {
  text-align: center;
  margin-top: 22px;
  span {
    cursor: pointer;
    color: #0496FF;
    text-decoration: underline;
  }
}

.tab-dashboard {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  .item-tab {
    cursor: pointer;
    padding: 4px 16px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    margin-right: 6px;
  }
}