@import '../../../common/commonConstant.scss';

.Loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $loading-bg;
  z-index: 999;
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    .box-loading {
      background-color: $loading-box-bg;
      padding: 10px 20px;
      border-radius: 10px;
      .image-loading {
        max-width: 64px;
        margin-right: 30px;
      }
      .image-logo {
        max-width: 180px;
      }
    }
  }
}
