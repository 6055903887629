.ForgotPasswordSuccess {
  .box-form {
    text-align: center;
    .icon {
      max-width: 42px;
      margin-bottom: 10px;
    }
    .description {
      margin-bottom: 50px;
    }
  }
}
