@import '../../common/commonConstant.scss';

.AreaManagement {
  .area-item {
    display: flex;
    align-items: center;
    padding: 15px 22px;
    border-radius: 5px;
    background: #fff;
    .area-item-logo {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      border: 1px solid #002F6C;
      background: #fff;
      overflow: hidden;
      margin-right: 23px;
    }
    .area-item-title {
      font-size: 24px;
      color: #002F6C;
    }
  }
}