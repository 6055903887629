.ForgotPassword {
  .description {
    margin-bottom: 30px;
  }
  .btn {
    margin-top: 50px;
  }
  .link {
    text-align: center;
    margin-top: 30px;
  }
}
