@import '../../common/commonConstant.scss';

.RoleManagement {
  .title-white-box {
    display: flex;
    align-items: center;
    .title-white-box-r {
      flex: 1;
      font-size: 30px;
      color: $text-color-blue;
    }
    .title-white-box-l {

    }
  }
  .last-title {
    font-size: 20px;
  }
  button {
    font-size: 14px !important;
    margin-left: 15px;
    color: #888;
    &.btn-secondary {
      background: $text-color-blue !important;
      color: #fff !important;
      width: 110px;
    }
  }
  .items-roles>div {
    margin-bottom: 12px;
    font-size: 14px;
    &._active {
      color: #41c6ff;
      >i {
        border: 1px solid #41c6ff;
      }
    }
     >i {
      border: 1px solid $text-color-blue;
      border-radius: 50%;
      padding: 4px;
    }
  }
  .footer-role-management {
    font-size: 14px;
    margin: 0 -20px -35px -20px;
    padding: 24px 20px;
    background: #eee;
    >div {
      margin-right: 16px;
      i {
        color: #aaa;
        margin-right: 4px;
      }
    }
  }
}

.items-check-box {
  display: flex;
  align-items: center;
  >div.item {
    position: relative;
    padding-left: 26px;
    margin-right: 18px;
    cursor: pointer;
    &::before, &._active::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border: 1px solid #ccc;
      border-radius: 50%;
    }
    &._active::after {
      top: 5px;
      left: 5px;
      background: $text-color-blue;
      width: 10px;
      height: 10px;
    } 
  }
}