@import '../../common//commonConstant.scss';

.Login {
  .box-page {
    .box-form {
      .title {
        margin-bottom: 30px;
      }
      .link {
        text-align: right;
        margin: 15px 0px 50px;
      }
    }
  }
}
