@import '../../common/commonConstant.scss';

.DatePicker {
  position: relative;
  width: 100%;
  .box-input {
    display: flex;
    align-items: center;
    border: $border-main;
    border-radius: 4px;
    .input {
      padding: 5px 10px;
      font-size: 14px;
      color: $text-color-black2;
      width: calc(100% - 37px);
      &.placeholder {
        color: $input-placeholder;
      }
    }
    .box-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border-left: $border-main;
      width: 37px;
      .icon {
        font-size: 16px;
        color: $text-color-blue;
      }
    }
  }
  .box-calendar {
    display: none;
    position: absolute;
    z-index: 100;
    background-color: $datepicker-bg;
    box-shadow: $datepicker-shadow;
    border-radius: 5px;
    top: 40px;
    margin-bottom: 15px;
    &.active {
      display: unset;
    }
    .DayPicker {
      &:not(.DayPicker--interactionDisabled) {
        .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
          background-color: $datepicker-active-date;
          border-radius: 50% !important;
          color: $text-color-white;
        }
      }
      .DayPicker-wrapper {
        padding: 0px;
        .DayPicker-NavBar {
          position: absolute;
          right: 16px;
          top: calc((43px / 2) - 10px);
          .DayPicker-NavButton {
            position: unset;
            margin: unset;
          }
        }
        .DayPicker-WeekdaysRow {
          display: flex;
          align-items: center;
          .DayPicker-Weekday {
            padding: 0px;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 5px;
            &:first-child {
              margin-left: 0px;
            }
            &:last-child {
              margin-right: 0px;
            }
            > * {
              cursor: unset;
            }
          }
        }
        .DayPicker-Month {
          margin: 58px 15px 15px;
          .DayPicker-Week {
            display: flex;
            margin: 5px 0px;
            .DayPicker-Day {
              position: relative;
              padding: 0px;
              margin: 0px 5px;
              width: 30px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .DayPicker-Day--selected {
              background-color: unset;
              color: $text-color-white;
              position: relative;
              z-index: 1;
              &::after {
                position: absolute;
                content: '';
                width: 30px;
                height: 30px;
                background-color: $datepicker-active-date;
                border-radius: 50%;
                z-index: -1;
              }
            }
          }
        }
        .box-month-year {
          display: flex;
          position: absolute;
          top: 15px;
          z-index: 2;
          .Dropdowns {
            &:first-child {
              margin-right: 5px;
            }
            .placeholder, .text {
              font-size: 14px;
            }
            .icon {
              padding: 10px;
            }
            .item {
              padding: 5px 10px;
            }
          }
        }
      }
    }
  }
}
