@import '../../common//commonConstant.scss';

.ChangePasswordSuccess {
  .icon {
    color: $text-color-green;
    text-align: center;
    font-size: 35px;
    margin-bottom: 10px;
  }
  .description {
    text-align: center;
    margin-bottom: 75px;
  }
}
