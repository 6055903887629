@import '../../common/commonConstant.scss';

.RadioButton {
  display: flex;
  align-items: center;
  .check {
    display: flex;
    background: $radio-button-bg;
    border: $border-main;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    padding: 5px;
    .active {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: $radio-button-active;
    }
  }
  .text {
    font-size: 16px;
    color: $text-color-black2;
  }
}
