.ButtonWithIcon {
  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 160px;
    .text {
      font-size: 18px;
      font-weight: 400;
      margin-right: 30px;
    }
    .icon {
      font-size: 16px;
    }
  }
}
