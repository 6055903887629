.area-org-header {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
  >.area-org-l {
    flex: 1;
    display: flex;
    align-items: center;
    div {
      position: relative;
      margin-right: 15px;
    }
    .area-item-logo {
      display: flex;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      border: 1px solid #002F6C;
      background: #fff;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .area-item-title {
      font-size: 24px;
      color: #002F6C;
      small {
        position: absolute;
        top: -10px;
        font-size: 14px;
        color: #666;
      }
    }
  }
  >.area-org-r {
    >a {
      border-radius: 4px;
      color: #fff;
      display: block;
      line-height: 34px;
      height: 34px;
      font-size: 14px !important;
      padding: 2px 14px !important;
      background: linear-gradient(360deg, #4CAF50 0%, #63D168 100%) !important;
      text-decoration: none;
      i {
        margin-left: 21px;
      }
    }
  }
}