@import '../../common/commonConstant.scss';

.AddNewUserSuccess {
  .template-white-box {
    position: relative;
    padding: 30px 45px !important;
    .line-left {
      position: absolute;
      background-color: $add-new-user-success-line-bg;
      width: 15px;
      top: 0;
      bottom: 0;
      left: 0;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    .box-detail {
      display: flex;
      align-items: center;
      .box-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        background-color: $add-new-user-success-icon-bg;
        border-radius: 50%;
        margin-right: 30px;
        .icon {
          font-size: 25px;
          color: $add-new-user-success-icon;
        }
      }
      .detail {
        color: $text-color-black2;
        .title {
          font-weight: 500;
          font-size: 20px;
        }
        .description {
          font-size: 14px;
          margin-bottom: 15px;
        }
      }
    }
  }
  .box-btn {
    text-align: center;
    margin-top: 30px;
    .btn {
      width: 160px;
    }
  }
}
