@import '../../../common//commonConstant.scss';

.Navbar {
  background-color: $nav-bar-bg;
  padding: 15px 30px;
  box-shadow: $nav-bar-shadow;
  position: fixed;
  width: inherit;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .box-detail {
    .box-title {
      display: flex;
      .icon {
        color: $text-color-blue;
        font-size: 20px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        height: 35px;
      }
      .menu-title {
        color: $text-color-black;
        font-size: 24px;
      }
    }
    .box-menu {
      font-size: 16px;
      color: $text-color-gray;
    }
  }
  .box-profile {
    display: flex;
    align-items: center;
    // border: $nav-bar-boder-profile;
    padding: 5px;
    border-radius: 100px;
    &.name {
      width: 170px;
      .image {
        margin-right: 10px;
      }
    }
    .image {
      width: 100%;
      max-width: 40px;
      max-height: 40px;
      object-fit: contain;
      border-radius: 50%;
    }
    .text {
      margin-left: 12px;
      font-size: 16px;
      color: $text-color-black;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
