@import '../../common//commonConstant.scss';

.ForceChangePassword {
  .description {
    text-align: center;
  }
  .warning-text {
    font-size: 14px;
    color: $text-color-red2;
  }
  .btn-submit {
    margin-top: 50px;
  }
}
