// font
@import url('https://fonts.googleapis.com/css?family=Kanit:300,400,500,600,700&display=swap&subset=thai'); // normal=400 bold=700

$font-family-kanit: 'Kanit', sans-serif;

// hr
$hr-border: 1px solid #f0f0f0;

// input
$input-placeholder: rgba(17, 15, 36, 0.4);
$input-placeholder-focus: 1px solid #012b62;
$input-placeholder-disable: 1px solid #ced4da;
$input-placeholder-disable-bg: #f3f3f3;
$input-placeholder-disable-text: #999999;
$input-placeholder-error: 1px solid #ff0000;

// border main
$border-main: 1px solid #ced4da;

// link
$link-text: #012e69;
$link-text-hover: #003d8c;
$link-text-active: #012656;

// button
$btn-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
$btn-primary-bg: linear-gradient(360deg, #002F6C 0%, #012A5F 100%);
$btn-primary-bg-hover: #003d8c;
$btn-primary-bg-active: #012657;
$btn-primary-text: #ffffff;
$btn-secondary-bg: #ffffff;
$btn-secondary-bg-hover: #f1f1f1;
$btn-secondary-bg-active: #e0e0e0;
$btn-secondary-text: #727272;
$btn-thirdary-bg: linear-gradient(360deg, #4CAF50 0%, #63D168 100%);
$btn-thirdary-bg-hover: #63d168;
$btn-thirdary-bg-active: #4caf50;
$btn-thirdary-text: #ffffff;

// text color
$text-color-white: #ffffff;
$text-color-blue: #002f6c;
$text-color-blue-hover: #10bef0;
$text-color-black: #1b1b1b;
$text-color-black2: #4f5256;
$text-color-gray: #9c9c9c;
$text-color-gray2: rgba(255, 255, 255, 0.4);
$text-color-gray3: #c5cdd6;
$text-color-red: #fc0d1b;
$text-color-red2: #ff0000;
$text-color-green: #20e56b;

// backgroung color
$background-color-gray: #f6f8fa;

// loading
$loading-bg: rgba(0, 0, 0, 0.3);
$loading-box-bg: #e5e5e5;

// side bar
$side-bar-bg: #002f6c;
$side-bar-menu-active: rgba(2, 35, 77, 0.4);

// nav bar
$nav-bar-bg: #ffffff;
$nav-bar-shadow: 0px 4px 4px rgba(0, 47, 108, 0.1);
$nav-bar-boder-profile: 1px solid rgba(0, 47, 108, 0.3);

// menu bar
$menu-bar-bg: rgba(0, 47, 108, 0.05);

// template center
$template-center-form-login-bg: #ffffff;
$template-center-form-login-bg-shadow: 0px 1px 10px rgba(142, 153, 175, 0.2);

// template error
$template-error-title: rgba(0, 47, 108, 0.05);

// template white box
$template-white-box-bg: #ffffff;
$template-white-box-shadow: 0px 4px 10px rgba(142, 153, 175, 0.2);

// radio button
$radio-button-bg: #ffffff;
$radio-button-active: #002f6c;

// dropdowns
$dropdowns-popup-border: 1px solid #ced4da;
$dropdowns-popup-shadow: 0px 0px 10px rgba(142, 153, 175, 0.2);
$dropdowns-popup-bg: #ffffff;
$dropdowns-popup-item-hover: linear-gradient(90deg, #EAEEF3 0%, #F7F7F7 100%);
$dropdowns-popup-image-bg: #ffffff;
$dropdowns-popup-image-border: 1px solid #002f6c;

// add new user by type
$add-new-user-by-type-upload-bg: #fdfdfd;
$add-new-user-by-type-upload-border: 1px solid rgba(206, 212, 218, 0.5);

// add new user success
$add-new-user-success-line-bg: #51b655;
$add-new-user-success-icon: #51b655;
$add-new-user-success-icon-bg: rgba(81, 182, 85, 0.2);

// date picker
$datepicker-active-date: #002f6c;
$datepicker-bg: #ffffff;
$datepicker-shadow: 0px 4px 10px 7px rgba(142, 153, 175, 0.2);

// checkbox
$checkbox-border: 1px solid #ced4da;
$checkbox-bg-active: #002f6c;
