@import '../../../common/commonConstant.scss';

.SideBar {
  width: 270px;
  background-color: $side-bar-bg;
  min-height: 100vh;
  padding: 30px 10px 30px 20px;
  position: fixed;
  z-index: 80;
  height: 100%;
  overflow-y: auto;
  z-index: 3;
  &.size-small {
    width: unset;
    padding: 30px 20px;
    .box-logo {
      justify-content: center;
      margin: unset;
      margin-bottom: 15px;
      .logo {
        display: none;
      }
    }
    .title-section {
      margin-top: 50px;
      .text {
        display: none;
      }
    }
    .box-menu-item {
      padding: 10px 20px;
      justify-content: center;
      .menu-icon {
        margin: unset;
      }
      .menu-title {
        display: none;
      }
    }
  }
  .box-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    .logo {
      max-width: 215px;
      margin-left: -10px;
    }
    .icon-menu {
      font-size: 20px;
      color: $text-color-white;
      cursor: pointer;
    }
  }
  .title-section {
    font-size: 12px;
    color: $text-color-gray2;
    margin-top: 30px;
    margin-bottom: 5px;
  }
  .box-menu-item {
    color: $text-color-white !important;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    &:hover,
    &.active {
      background: $side-bar-menu-active;
      border-radius: 3px;
      text-decoration: unset;
    }
    .menu-icon {
      font-size: 18px;
      margin-right: 10px;
    }
    .menu-title {
      font-size: 13px;
    }
  }
}
