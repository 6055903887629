@import '../../common/commonConstant.scss';

.Checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  .box-check {
    border: $checkbox-border;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      background-color: $checkbox-bg-active;
    }
    .icon {
      font-size: 10px;
      color: $text-color-white;
    }
  }
  .label {
    font-size: 14px;
    color: $text-color-black2;
    margin-left: 5px;
  }
}
