.AreaOrganizeEdit {
  .area-info-detail {
    display: flex;
    font-size: 14px;
    margin-top: -24px;
    > div {
      margin-right: 15px;
      color: #888;
      i {
        color: #ccc;
        margin-right: 2px;
      }
    }
  }
  .organize-detail-edit {
    margin-top: 32px;
    display: flex;
    button {
      font-size: 14px !important;
      margin-left: 15px;
      color: #888;
      &.btn-secondary {
        background: #002f6c !important;
        color: #fff !important;
        width: 110px;
      }
    }
    >.organize-detail-edit-l {
      width: 360px;
      margin-right: 32px;
    }
    >.organize-detail-edit-r {
      flex: 1;
      min-height: 200px;
      background: #FDFDFD;
      box-shadow: 0px 4px 10px rgba(142, 153, 175, 0.2);
      border-radius: 5px;
      padding: 14px 24px 24px 24px;
    }
    .organize-box {
      background: #FDFDFD;
      box-shadow: 0px 4px 10px rgba(142, 153, 175, 0.2);
      border-radius: 5px;
    }
    .header-org {
      padding: 12px 16px;
      color: #aaa;
      font-size: 14px;
    }
    .org-list-item {
      position: relative;
      border-top: 1px solid #E5E5E5;
      padding: 6px 16px;
      cursor: pointer;
      &::before {
        position: absolute;
        font-family: 'icomoon' !important;
        font-size: 12px;
        content: '\e90d';
        display: flex;
        align-items: center;
        width: 22px;
        height: 26px;
        right: 12px;
        color: #888;
      }
    }
    .organize-buttom {
      text-align: right;
    }
    .organize-detail-header {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      ._org-l {
        font-size: 24px;
        flex: 1;
      }
      ._org-r {
        cursor: pointer;
        font-size: 14px;
        color: #aaa;
        &:hover {
          color: #c33;
        }
      }
    }
    .organize-detail-doby {
      display: flex;
      margin-bottom: 16px;
      .org-detail-doby-l {
        flex: 1;
      }
      .org-detail-doby-c {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          transform: rotate(-90deg);
          color: #ddd;
        }
      }
      .org-detail-doby-r {
        flex: 1;
      }
      .org-detail-list {
        border: 1px solid #eee;
        padding: 12px 20px;
        border-radius: 5px;
        max-height: 460px;
        overflow-y: scroll;
        &.odl_r {
          background: rgba(234, 238, 243, 0.5);
          .org-detail-list-item {
            background: #fff;
            border: 1px solid transparent;
            border-radius: 4px;
            padding: 8px 16px;
            margin: 8px 0;
            &::before {
              content: '\e910';
              color: #c33;
            }
          }
        }
        >input {
          margin-bottom: 14px;
        }
        .org-detail-list-item {
          cursor: pointer;
          position: relative;
          border-bottom: 1px solid #eaeaea;
          padding: 8px 0;
          font-size: 14px;
          &::before {
            position: absolute;
            font-family: 'icomoon' !important;
            font-size: 12px;
            content: '\e90b';
            display: flex;
            align-items: center;
            width: 22px;
            height: 26px;
            right: 12px;
            color: #888;
          }
        }
      }
      .org-detail-doby-title {
        font-size: 14px;
        margin-bottom: 4px;
        color: #aaa;
      }
    }
    .organize-detail-footer {
      text-align: right;
    }
  }
}