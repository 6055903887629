@import '../../common/commonConstant.scss';

.UserManagenemtAdd {
  .upload-box {
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: center;
    color: $text-color-blue;
    i {
      display: block;
      font-size: 42px;
    }
  }
  #upload-users-lists {
    display: none;
  }
  .example-upload {
    font-size: 13px;
    font-weight: 500;
    a {
      &:hover {
        text-decoration: none;
      }
    }
    span {
      cursor: pointer;
      margin-left: 18px;
      color: #41C6FF;
    }
  }
  table.tb-verify {
    td.invalid {
      background: #ee333333;
      font-weight: 500;
      color: #e33;
    }
  }
}

.form-user {
  small {
    color: #777;
  }
  p {
    margin-bottom: 0 !important;
  }
}

.UserManagenemtProfile {
  .template-white-box >.row.bg-light {
    margin: 15px -20px;
    padding: 5px 5px 15px 5px;
  }
  .form-user-avetar {
    padding-right: 15px;
    padding-left: 15px;
    .user-box-avetar {
      width: 120px;
      height: 120px;
      background: #eee;
      border-radius: 4px;
      overflow: hidden;
    }
  }
  .role-detail {
    position: relative;
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 18px 40px 15px 40px;
    >.role-detail-number {
      position: absolute;
      left: 8px;
      top: 4px;
      font-size: 12px;
      color: #777;
      font-weight: 500;
    }
    >.role-detail-del {
      cursor: pointer;
      position: absolute;
      right: 8px;
      top: 4px;
      font-size: 12px;
      color: #c00;
      font-weight: 500;
    }
    >div {
      flex: 1;
      margin: 0 15px;
    }
  }
  button {
    font-size: 14px !important;
    margin-left: 15px;
    color: #888;
    &.btn-success {
      color: #fff;
    }
    &.btn-secondary {
      background: $text-color-blue !important;
      color: #fff !important;
      width: 110px;
    }
  }
}